<template>
  <div>
    <span
      style="
        display: block;
        color: white;
        text-align: center;
        margin-bottom: 50px;
        font-size: 1.2vw;
        font-weight: bold;
      "
      >Discount codes for Coral Tribe's limited merch collection, powered by
      $AQUA</span
    >
    <div class="merch-container">
      <div
        class="raffle-page-background div-merch"
        style="margin-bottom: 100px"
        v-for="(discount, key) in discounts"
        :key="key"
      >
        <div class="raffle-page-card">
          <span class="raffle-page-title">{{ discount.name }}</span>
          <div class="raffle-page-line"></div>

          <div class="discount-options">
            <div
              class="discount-option"
              v-for="(discount_option, discount_key) in discount.discounts"
              :key="discount_key"
              @click="select_discount(discount, discount_option)"
              :class="{ selected: discount_option.selected === true }"
            >
              {{ discount_option.discount }} %<br />
              <span>{{ discount_option.aqua }} AQUA</span>
            </div>
          </div>

          <div class="raffle-page-info">
            <div class="raffle-page-nft">
              <img :src="'/assets/images/' + discount.id + '.jpg'" />
            </div>

            <div class="raffle-page-details">
              <div class="raffle-page-details-text">
                <p>
                  <span>Price: </span>
                  <span
                    >{{ discount.selected.aqua }} $AQUA / -{{
                      discount.selected.discount
                    }}%</span
                  >
                </p>
                <p class="raffle-page-tickets">
                  <span
                    class="raffle-page-gradient-font gradient-font"
                    @click="withdraw_one_ticket(discount.selected)"
                    >&#x2212;</span
                  >
                  <span>{{ discount.how_many_tickets }}</span>
                  <span
                    class="raffle-page-gradient-font gradient-font"
                    @click="add_one_ticket(discount.selected)"
                    >&#x2b;</span
                  >
                </p>
              </div>

              <div class="raffle-page-button button">
                <div class="button-left-border"></div>
                <div class="button-right-border"></div>
                <div
                  class="button-content"
                  @click="buy_tickets(discount.selected)"
                >
                  <span>Buy</span>
                  <span>{{ discount.selected.how_many_tickets }}</span>
                  <span>Code</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { buy_discount_program } from "@/libs/rustProgram";
import { getWalletAddress, getSolanaObject } from "@/libs/wallet";
import { raffle_timer_time_left_for_raffle } from "@/libs/raffleTimer";
import { establishConnection } from "@/libs/solanaConnection";
import { cct_alert } from "@/libs/CCT_alert";

import axios from "axios";

let config_axios = {
  headers: {
    "Content-Type": "application/json;",
  },
};

let backend_url = "https://cct.effe2.xyz/";
// let backend_url = 'http://37.187.116.60/cct/public/';

export default {
  name: "DiscountCodes",
  data: function () {
    return {
      discounts: [
        { image: "", name: "Tees", id: 1, discounts: [], selected: {} },
        { image: "", name: "Hats", id: 2, discounts: [], selected: {} },
        { image: "", name: "Hoodies", id: 3, discounts: [], selected: {} },
      ],
    };
  },
  methods: {
    withdraw_one_ticket: function (discount) {
      discount.how_many_tickets--;

      if (discount.how_many_tickets <= 0) discount.how_many_tickets = 1;
    },

    add_one_ticket: function (discount) {
      discount.how_many_tickets++;
    },

    select_discount: function (discount, discount_option_selected) {
      for (var discount_option of discount.discounts) {
        discount_option.selected = false;
      }

      discount_option_selected.selected = true;

      discount.selected = discount_option_selected;
    },

    buy_tickets: async function (discount) {
      if (this.$root.wallet_address === null) {
        // alert('Please connect your wallet first');
        this.$root.$emit("display_connection_modal");
        return;
      }

      this.$root.loading_gif = true;

      console.log(discount);

      var signature = await buy_discount_program(
        getSolanaObject(),
        getWalletAddress(),
        discount.how_many_tickets,
        discount.escrow_account
      );

      console.log(signature);

      this.$root.loading_gif = false;
    },

    confirm_transactions: async function (signatures) {
      var result = {
        success: 0,
        error: 0,
      };

      let connection = await establishConnection();

      for (var index in signatures) {
        let signature = signatures[index];

        if (signature === false) {
          result.error++;
        } else {
          try {
            var confirmation = await connection.getConfirmedTransaction(
              signature
            );

            result.success++;
          } catch (e) {
            result.error++;
          }
        }
      }

      return result;
    },
  },
  mounted: async function () {
    var component = this;

    axios
      .get(backend_url + "shop/discount/get_per_category")
      .then(function (result) {
        // component.discounts = result.data.response.discounts;
        component.discounts[0].discounts = result.data.response.discounts[1];
        component.discounts[1].discounts = result.data.response.discounts[2];
        component.discounts[2].discounts = result.data.response.discounts[3];

        console.log(result.data.response.discounts);

        if (result.data.response.discounts[1][0]) {
          component.discounts[0].selected =
            result.data.response.discounts[1][0];
          component.discounts[0].discounts[0].selected = true;
        }
        if (result.data.response.discounts[2][0]) {
          component.discounts[1].selected =
            result.data.response.discounts[2][0];
          component.discounts[1].discounts[0].selected = true;
        }
        if (result.data.response.discounts[3][0]) {
          component.discounts[2].selected =
            result.data.response.discounts[3][0];
          component.discounts[2].discounts[0].selected = true;
        }
      });
  },
};
</script>

<style scoped>
/*
.merch-container {
  padding: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}



.raffle-page-background {
  width: 75%;
}

.raffle-page-nft {
  width: 33%;
}

.raffle-page-nft img {
  height: 100%;
  left: 0%;
}

.raffle-page-info {
  justify-content: space-around;
  gap: 30px;
  padding-bottom: 50px;
}
*/

.discount-options {
  display: flex;
  color: rgb(69, 69, 69);
  justify-content: space-around;
}

.discount-option {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 5px 15px;
  border: 1px solid rgb(69, 69, 69);
  cursor: pointer;
}
.discount-option.selected {
  border: 1px solid rgb(188, 188, 188);
  color: rgb(188, 188, 188);
}

.discount-option span {
  font-size: 15px;
}
</style>
